import AdminNavbar from "./AdminNavbar";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Button, Card, CardContent } from "@mui/material";
import { useEffect, useState } from "react";
import { db, deletestorageRef } from "./Firebase";
import { Delete } from "@mui/icons-material";

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export default function AdminEdit() {

    const [imageData, setimageData] = useState([]);

    function getSsImages() {
        db.collection('Success_story_images').get().then((succ) => {
            setimageData(succ.docs);
        })
    }

    useEffect(() => {
        getSsImages();
    }, [])

    const [transImg, settransImg] = useState([]);

    function gettransImg() {
        db.collection("Best_transformation_images").get().then((succ) => {
            settransImg(succ.docs);
        })
    }

    useEffect(() => {
        gettransImg();
    }, [])

    function deleteSsImages(x) {
        console.log(x);
        db.collection("Success_story_images").doc(x.id).delete().then((succ) => {
            if (x.data().Image) {
                deletestorageRef.refFromURL(x.data().Image).delete().then((succ) => {
                    console.log("ok")
                    getSsImages();
                })
            }
        })
    }

    function deletetransImg(x) {
        console.log(x);
        db.collection("Best_transformation_images").doc(x.id).delete().then((succ) => {
            if (x.data().Image) {
                deletestorageRef.refFromURL(x.data().Image).delete().then((succ) => {
                    console.log("ok")
                    gettransImg();
                })
            }
        })
    }
    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AdminNavbar />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <DrawerHeader />
                    <div className="container m-auto">
                        <h1 className="text-center txt-clr fw-bold">Image Data</h1>
                        <div className="py-2">
                            <Card>
                                <CardContent>
                                    <h1 className="text-center">Success Stories</h1>
                                    <table className="table table-hover">
                                        <thead>
                                            <tr className="text-center">
                                                <th>Name</th>
                                                <th>Image</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {imageData.map((row) => (
                                                <tr>
                                                    <td className="text-center">{row.data().Name}</td>
                                                    <td className="d-flex justify-content-center">
                                                        <img src={row.data().Image} width={50} />
                                                    </td>
                                                    <td className="text-center">
                                                        <Button onClick={() => deleteSsImages(row)} color="error"><Delete /></Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </CardContent>
                            </Card>
                        </div>
                        <div className="py-2">
                            <Card>
                                <CardContent>
                                    <h1 className="text-center">Transformation of the month</h1>
                                    <table className="table table-hover">
                                        <thead>
                                            <tr className="text-center">
                                                <th>Name</th>
                                                <th>Image</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transImg.map((row) => (
                                                <tr>
                                                    <td className="text-center">{row.data().Name}</td>
                                                    <td className="d-flex justify-content-center">
                                                        <img src={row.data().Image} width={50} />
                                                    </td>
                                                    <td className="text-center">
                                                        <Button onClick={() => deletetransImg(row)} color="error"><Delete /></Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </Box>
            </Box>
        </>
    )
}