import Navbar from "./Navbar";
import Footer from "./Footer"
import aboutImg1 from "./images/about-img-1.webp"
import aboutImg2 from "./images/about-img-2.webp"
import aboutImg3 from "./images/about-img-3.webp"
import aboutImg4 from "./images/about-img-4.webp"
import aboutImg5 from "./images/about-img-5.webp"
import aboutImg6 from "./images/about-img-6.webp"
import aboutImg7 from "./images/about-img-7.webp"
import aboutImg8 from "./images/about-img-8.webp"
import { Height } from "@mui/icons-material";



export default function About() {

    return (
        <div className="about-bg overflow-scrolls">
            <Navbar />
            <div className="container-fluid m-auto row">
                <h1 className="text-white text-uppercase text-center display-4 fw-bold"><span className='txt-clr'>About</span> Me</h1>
                <div className="w-100 d-flex justify-content-center">
                    <div className="grid-container">
                        <div className="collage-div">
                            <img className='grid-item grid-item-1' src={aboutImg6} alt='' />
                        </div>
                        <div className="collage-div">
                            <img className='grid-item grid-item-2' src={aboutImg2} alt='' />
                        </div>
                        <div className="collage-div">
                            <img className='grid-item grid-item-3' src={aboutImg3} alt='' />
                        </div>
                        <div className="collage-div">
                            <img className='grid-item grid-item-4' src={aboutImg4} alt='' />
                        </div>
                        <div className="collage-div">
                            <img className='grid-item grid-item-5' src={aboutImg5} alt='' />
                        </div>
                        <div className="collage-div">
                            <img className='grid-item grid-item-6' src={aboutImg1} alt='' />
                        </div>
                        <div className="collage-div">
                            <img className='grid-item grid-item-7' src={aboutImg7} alt='' />
                        </div>
                        <div className="collage-div">
                            <img className='grid-item grid-item-8' src={aboutImg8} alt='' />
                        </div>
                    </div>
                </div>
                <div className="text-center text-white">
                    <h3>Ankush Kumar: From Adversity to Triumph - Mr World's Journey in Bodybuilding</h3>

                    <p>Ankush Kumar, 31, from Dakha, Ludhiana, achieved global recognition by securing the "Mr World" title and a gold medal in the Bodybuilding Championship's under-70 kg category, hosted by the Fitness International Federation in Singapore on April 3. Battling hardships, he conquered the odds, claiming gold, two silvers, and a bronze across diverse bodybuilding categories.
                    </p>
                    <p>Ankush Kumar's narrative is one of resilience and determination. Hailing from a modest background with a paralyzed father, financial constraints, and a challenging upbringing, Kumar's journey to success is a testament to his unwavering spirit.</p>

                    <p>Struggling with financial limitations, he began his journey in bodybuilding after finding inspiration from an electrician's physique at a factory where he worked. Initially, he worked as a cleaner in a local gym, fostering his passion for bodybuilding. Balancing his aspirations with financial responsibilities, he juggled odd jobs, including working at a nearby restaurant, washing dishes to sustain himself and afford a basic diet required for the sport.</p>

                    <p>Despite financial struggles, Kumar's thirst for knowledge and education was evident. Initially leaving school after Class 10 due to financial constraints, his Punjabi teacher, Ranjit Kaur, encouraged him to continue his studies, leading him to complete his Class 12 from the Open University. Driven by his dedication to learning, he pursued over 150 national and international bodybuilding courses.</p>

                    <p>However, Kumar faced significant setbacks, enduring serious injuries in 2013, including broken bones and subsequent surgeries. Determined to achieve his dream of becoming Mr World, he transitioned from powerlifting to bodybuilding. His relentless dedication led him to triumph, securing multiple titles at national and international levels.</p>

                    <p>Ankush Kumar's journey encapsulates a narrative of unwavering perseverance, overcoming adversity to claim the pinnacle of success in the world of bodybuilding.</p>
                </div>
            </div>
            <Footer />
        </div>
    )
}