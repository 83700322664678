import { Link } from "react-router-dom";
import brandLogo from './images/2.png'
import { Button, Grid, Typography } from "@mui/material";
import { Close, Instagram, Menu, WhatsApp } from "@mui/icons-material";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect } from "react";


// gsap.registerPlugin(ScrollTrigger);
export default function Navbar() {
    // useEffect(() => {
    //     // GSAP animation timeline
    //     const timeline = gsap.timeline({
    //         scrollTrigger: {
    //             trigger: "header",
    //             scroller: "body",
    //             start: "top -100%",
    //             end: "top -100%",
    //             scrub: 0.5, // Adjust the scrub value based on your preference
    //         },
    //     });

    //     // Animation for changing position from absolute to fixed
    //     timeline.to(".navbar", {
    //         backgroundColor: "rgba(0,0,0,0.2)",
    //         backdropFilter: "blur(20px)",
    //         fontSize: "15px",
    //         position: "fixed",
    //         padding: "0px",
    //         duration: 1,
    //         delay: 0.8,
    //     });
    // }, []);
    return (
        <>
            <header id="news-banner">
                <div className="container m-auto">
                    <Typography className="text-white float-start p-2">Welcome to AK Fitness World</Typography>
                    <a href="https://www.instagram.com/ak_fitness_world/" className="float-end p-2 text-white fw-light text-decoration-none">
                        <span>Get latest Updates</span>&nbsp;
                        <Instagram style={{ color: 'white', background: 'linear-gradient(45deg, #8a3ab9, #e1306c, #fd7e14)', borderRadius: '20%' }} />
                    </a>

                </div>
            </header>
            <header className="navbar bg-transparent w-100">
                <div className="container m-auto">
                    <Link to="/" className="navbar-brand text-white"><img src={brandLogo} className="rounded-circle shadow brandL" /></Link>
                    <ul className="nav d-none d-lg-flex">
                        <li className="nav-item"><Link className="nav-link text-white" to="/">Home</Link></li>
                        <li className="nav-item"><Link className="nav-link text-white" to="/About">About</Link></li>
                        <li className="nav-item"><Link className="nav-link text-white" to="/Services">Services</Link></li>
                        <li className="nav-item"><Link className="nav-link text-white" to="/Blog">Blog</Link></li>
                        <li className="nav-item"><Link className="nav-link text-white" to="/Contact">Contact</Link></li>
                    </ul>
                    <ul className="nav d-lg-none">
                        <Button className="text-white" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop" aria-controls="staticBackdrop"><Menu /></Button>
                        <div class="offcanvas offcanvas-end bg-black" data-bs-backdrop="static" tabindex="-1" id="staticBackdrop" aria-labelledby="staticBackdropLabel">
                            <div className="offcanvas-header">
                                <Button type="button" className="bg-transparent border-0 text-white" data-bs-dismiss="offcanvas" aria-label="Close"><Close /></Button>
                            </div>
                            <div class="offcanvas-body">
                                <ul className="text-center">
                                    <li className="nav-item p-2"><Link className="nav-link text-white" to="/">Home</Link></li>
                                    <li className="nav-item p-2"><Link className="nav-link text-white" to="/About">About</Link></li>
                                    <li className="nav-item p-2"><Link className="nav-link text-white" to="/Services">Services</Link></li>
                                    <li className="nav-item p-2"><Link className="nav-link text-white" to="/Blog">Blog</Link></li>
                                    <li className="nav-item p-2"><Link className="nav-link text-white" to="/Contact">Contact</Link></li>
                                </ul>
                            </div>
                            <Grid container padding={10} justifyContent={"center"}>
                                <Link to="/" className="offcanvas-title col-6 offset-3" id="staticBackdropLabel">
                                    <img src={brandLogo} width={100} className="rounded-circle" />
                                </Link>
                            </Grid>
                        </div>
                    </ul>
                </div>
            </header>
        </>
    )
}