import React, { useEffect, useState } from 'react'
import atinderTransformation from './images/atinder-transformation.png'
import { Typography } from '@mui/material'
import { db } from './Firebase';


export default function TransOfMonth() {

    const [transImg, settransImg] = useState([]);

    function gettransImg() {
        db.collection("Best_transformation_images").get().then((succ) => {
            settransImg(succ.docs);
        })
    }

    useEffect(() => {
        gettransImg();
    }, [])

    const [transH, settransH] = useState([]);

    function gettransH() {
        db.collection("trans_h").get().then((succ) => {
            settransH(succ.docs);
        })
    }

    useEffect(() => {
        gettransH();
    }, [])

    const [transD, settransD] = useState([]);

    function gettransD() {
        db.collection("trans_description").get().then((succ) => {
            settransD(succ.docs);
        })
    }

    useEffect(() => {
        gettransD();
    }, [])

    return (
        <div>
            {/* <section>
                <div className="container m-auto row">
                    <h1 className="text-white text-uppercase text-center display-4 fw-bold p-2"> <span className='txt-clr'>Best Transformation</span> of the Month</h1>
                    <div className="col-lg-6">
                        {transImg.map((row) => (
                            <img src={row.data().Image} className='img-fluid p-lg-3' />
                        ))}
                    </div>
                    <div className="col-lg-6 text-white">
                        {transH.map((row) => (
                            <h3 className="text-center p-3">Transformation Spotlight : {row.data().trans_heading}</h3>
                        ))}
                        {transD.map((row) => (
                            <>
                                <p>{row.data().Line_one}</p>
                                <p>{row.data().Line_two}</p>
                                <p>{row.data().Line_three}</p>
                                <p>{row.data().Line_four}</p>
                            </>
                        ))}
                    </div>
                </div>
            </section> */}

            {transImg.map((row) => (
                <section>
                    <div className="container m-auto row">
                        <h1 className="text-white text-uppercase text-center display-4 fw-bold p-2"> <span className='txt-clr'>Best Transformation</span> of the Month</h1>
                        <div className="col-lg-6">
                            <img src={row.data().Image} className='img-fluid p-lg-3' />

                        </div>
                        <div className="col-lg-6 text-white">
                            {transH.map((row) => (
                                <h3 className="text-center p-3">Transformation Spotlight : {row.data().trans_heading}</h3>
                            ))}
                            {transD.map((row) => (
                                <>
                                    <p>{row.data().Line_one}</p>
                                    <p>{row.data().Line_two}</p>
                                    <p>{row.data().Line_three}</p>
                                    <p>{row.data().Line_four}</p>
                                </>
                            ))}
                        </div>
                    </div>
                </section>
            ))}
        </div>
    )
}

