import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import 'react-multi-carousel/lib/styles.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import './App.css';
import './responisve.css'
import Home from "./Home";
import About from "./About";
import Services from "./Services";
import Blog from "./Blog";
import Contact from "./Contact"
import AdminPage from "./AdminPage";
import AdminEdit from "./AdminEdit";
import QrCodeGenerator from "./QrCodeGenerator";
import AdminContent from "./AdminContent";
import AdminLogin from "./AdminLogin";
import Memberships from "./Memberships";
import Subscribers from "./Subscribers";
import { Button } from "@mui/material";
import Preloader from "./Preloader";

function Error404() {
  var navi = useNavigate();
  return (
    <>
      <div className="w-100 h100">
        <Button onClick={() => navi("/")} variant="contained" className="btn-bg text-white fs-2 fw-bold position-absolute start-50 top-50">Go back to home page</Button>
      </div>
    </>
  )
}
function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/AdminPage" element={<AdminPage />} />
          <Route path="/AdminEdit" element={<AdminEdit />} />
          <Route path="/AdminContent" element={<AdminContent />} />
          <Route path="/QrCodeGenerator" element={<QrCodeGenerator />} />
          <Route path="/Memberships" element={<Memberships />} />
          <Route path="/Subscribers" element={<Subscribers />} />
          <Route path="/AdminLogin" element={<AdminLogin />} />
          <Route path="/Preloader" element={<Preloader />} />
          <Route path="*" element={<Error404 />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
