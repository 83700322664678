import AdminNavbar from "./AdminNavbar";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Button, ButtonGroup, Card, CardContent, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { db, deletestorageRef } from "./Firebase";
import { Close, Delete, WrongLocation } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export default function AdminContent() {
    var navi = useNavigate();
    function addHeading(e) {
        e.preventDefault()
        var data = new FormData(e.currentTarget)

        db.collection("trans_h").add({
            trans_heading: data.get("trans_h"),
        }).then((succ) => {
            e.target.reset();
            e.target.trans_h.focus();
        })
    }

    function addDesciption(e) {
        e.preventDefault()
        var data = new FormData(e.currentTarget)

        db.collection("trans_description").add({
            Line_one: data.get("p_1"),
            Line_two: data.get("p_2"),
            Line_three: data.get("p_3"),
            Line_four: data.get("p_4"),
        }).then((succ) => {
            e.target.reset();
            e.target.p_1.focus();
        })
    }

    const [transH, settransH] = useState([]);

    function gettransH() {
        db.collection("trans_h").get().then((succ) => {
            settransH(succ.docs);
        })
    }

    useEffect(() => {
        gettransH();
    }, [])

    const [transD, settransD] = useState([]);

    function gettransD() {
        db.collection("trans_description").get().then((succ) => {
            settransD(succ.docs);
        })
    }

    useEffect(() => {
        gettransD();
    }, [])

    var content = new URLSearchParams(window.location.search).get('id');

    function updateHeading(e) {
        e.preventDefault()
        var data = new FormData(e.currentTarget)

        db.collection("trans_h").doc(content).update({
            trans_heading: data.get("update_trans_h"),
        }).then((succ) => {
            e.target.reset();
            gettransH();
            setTimeout(() => {
                navi('/AdminContent');
            }, 100)
        })
    }

    function updateDescription(e) {
        e.preventDefault()
        var data = new FormData(e.currentTarget)

        db.collection("trans_description").doc(content).update({
            Line_one: data.get("u_p_1"),
            Line_two: data.get("u_p_2"),
            Line_three: data.get("u_p_3"),
            Line_four: data.get("u_p_4"),
        }).then((succ) => {
            e.target.reset();
            gettransD();
            setTimeout(() => {
                navi('/AdminContent');
            }, 100)
        })
    }

    function deleteHeading(x) {
        console.log(x);
        db.collection("trans_h").doc(x.id).delete().then((succ) => {
            gettransH();
        })
    }

    function deleteDescription(x) {
        console.log(x);
        db.collection("trans_description").doc(x.id).delete().then((succ) => {
            gettransD();
        })
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AdminNavbar />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <DrawerHeader />
                    <div className="container m-auto row">
                        <h1 className="fw-bold txt-clr text-center">Add Content</h1>

                        {content ?
                            (
                                <div className="col-lg-6 py-2">
                                    <Card>
                                        <Button className="float-end text-secondary" onClick={() => navi("/AdminContent")}><Close /></Button>
                                        <CardContent />
                                        <h1 className="text-center">Update Heading</h1>
                                        <CardContent>
                                            <form onSubmit={updateHeading}>
                                                <TextField name="update_trans_h" margin="normal" label="add heading..." size="small" fullWidth required />
                                                <div className="d-flex justify-content-center">
                                                    <Button type="submit" className="btn-bg fw-bold m-2" variant="contained">Update</Button>
                                                </div>
                                            </form>
                                        </CardContent>
                                    </Card>
                                </div>
                            )
                            :
                            (<div className="col-lg-6 py-2">
                                <Card>
                                    <h1 className="text-center">Add Heading</h1>
                                    <CardContent>
                                        <form onSubmit={addHeading}>
                                            <TextField name="trans_h" margin="normal" label="add heading..." size="small" fullWidth required />
                                            <div className="d-flex justify-content-center">
                                                <Button type="submit" className="btn-bg fw-bold m-2" variant="contained">Submit</Button>
                                            </div>
                                        </form>
                                    </CardContent>
                                    <CardContent>
                                        {transH.map((row) => (
                                            <div className="text-center ">
                                                <p>Heading : {row.data().trans_heading}</p>
                                                <Button onClick={() => deleteHeading(row)}><Delete /></Button>
                                                <Button className="fw-bold m-2" onClick={() => navi("/AdminContent?id=" + row.id)}>Edit</Button>
                                            </div>
                                        ))}
                                    </CardContent>
                                </Card>
                            </div>)
                        }

                        {content ? (
                            <div className="col-lg-6 py-2">
                                <Card>
                                    <Button className="float-end text-secondary" onClick={() => navi("/AdminContent")}><Close /></Button>
                                    <CardContent />
                                    <h1 className="text-center">Update Description</h1>
                                    <CardContent>
                                        <form onSubmit={updateDescription}>
                                            <TextField name="u_p_1" margin="normal" label="add line one..." size="small" fullWidth required />
                                            <TextField name="u_p_2" margin="normal" label="add line two..." size="small" fullWidth required />
                                            <TextField name="u_p_3" margin="normal" label="add line three..." size="small" fullWidth required />
                                            <TextField name="u_p_4" margin="normal" label="add line four..." size="small" fullWidth required />
                                            <div className="d-flex justify-content-center">
                                                <Button type="submit" className="btn-bg fw-bold m-2" variant="contained">Update</Button>
                                            </div>
                                        </form>
                                    </CardContent>
                                </Card>
                            </div>
                        ) : (
                            <div className="col-lg-6 py-2">
                                <Card>
                                    <h1 className="text-center">Add Description</h1>
                                    <CardContent>
                                        <form onSubmit={addDesciption}>
                                            <TextField name="p_1" margin="normal" label="add line one..." size="small" fullWidth required />
                                            <TextField name="p_2" margin="normal" label="add line two..." size="small" fullWidth required />
                                            <TextField name="p_3" margin="normal" label="add line three..." size="small" fullWidth required />
                                            <TextField name="p_4" margin="normal" label="add line four..." size="small" fullWidth required />
                                            <div className="d-flex justify-content-center">
                                                <Button type="submit" className="btn-bg fw-bold m-2" variant="contained">Submit</Button>
                                            </div>
                                        </form>
                                    </CardContent>
                                    <CardContent>
                                        {transD.map((row) => (
                                            <div className="text-center">
                                                <ul>
                                                    <li>1: {row.data().Line_one}</li>
                                                    <li>2: {row.data().Line_two}</li>
                                                    <li>3: {row.data().Line_three}</li>
                                                    <li>4: {row.data().Line_four}</li>
                                                </ul>
                                                <Button onClick={() => deleteDescription(row)}><Delete /></Button>
                                                <Button className="fw-bold m-2" onClick={() => navi("/AdminContent?id=" + row.id)}>Edit</Button>
                                            </div>
                                        ))}
                                    </CardContent>
                                </Card>
                            </div>
                        )}

                    </div>
                </Box>
            </Box>
        </>
    )
}