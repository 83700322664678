import { Button, Card, CardContent, TextField, Typography } from "@mui/material";
import Footer from "./Footer";
import Navbar from "./Navbar";
import nutritionIcon from "./images/nutrition-icon.png"
import workoutIcon from "./images/workout-icon.png"
import adviceIcon from "./images/advice-icon.png"
import certificationIcon from "./images/certification-icon.png"
import workshopsIcon from "./images/workshops-icon.png"
import onlinetrainingIcon from "./images/onlinetraining-icon.png"
import firebase from "firebase";
import { db } from "./Firebase";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ArrowRight } from "@mui/icons-material";
import Charges from "./Charges";

export default function Services() {

    function addUserDetails(e) {
        e.preventDefault()
        var data = new FormData(e.currentTarget);
        db.collection("Users").add({
            Name: data.get("userName"),
            Email_ID: data.get("userEmail"),
        }).then((succ) => {
            e.target.reset();
            e.target.userName.focus();
        })
    }

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <div id="charges" className="overflow-scrolls">
            <Navbar />
            <div className="container row m-auto">
                <h1 className="text-white text-uppercase text-center display-4 fw-bold txt-clr">Services</h1>

                <div className="col-lg-4 col-md-6 my-2 text-center">
                    <Card className="my-bg shadow">
                        <CardContent>
                            <img src={certificationIcon} className="card-img-w w-50" />
                            <h5 className="p-2 text-white">International personal trainer certification</h5>
                        </CardContent>
                    </Card>
                </div>
                <div className="col-lg-4 col-md-6 my-2 text-center">
                    <Card className="my-bg shadow">
                        <CardContent>
                            <img src={workshopsIcon} className="card-img-w w-50" />
                            <h5 className="p-2 text-white">Nutrition and workout workshops</h5>
                        </CardContent>
                    </Card>
                </div>
                <div className="col-lg-4 col-md-6 my-2 text-center">
                    <Card className="my-bg shadow">
                        <CardContent>
                            <img src={onlinetrainingIcon} className="card-img-w w-50" />
                            <h5 className="p-2 text-white">Online training for men as well as women</h5>
                        </CardContent>
                    </Card>
                </div>
                <div className="col-lg-4 col-md-6 my-2 text-center">
                    <Card className="my-bg shadow">
                        <CardContent>
                            <img src={nutritionIcon} className="card-img-w w-50" />
                            <h5 className="p-2 text-white">Diet plans</h5>
                        </CardContent>
                    </Card>
                </div>
                <div className="col-lg-4 col-md-6 my-2 text-center">
                    <Card className="my-bg shadow">
                        <CardContent>
                            <img src={workoutIcon} className="card-img-w w-50" />
                            <h5 className="p-2 text-white">Workout plans</h5>
                        </CardContent>
                    </Card>
                </div>
                <div className="col-lg-4 col-md-6 my-2 text-center">
                    <Card className="my-bg shadow">
                        <CardContent>
                            <img src={adviceIcon} className="card-img-w w-50" />
                            <h5 className="p-2 text-white">Supplements advisor</h5>
                        </CardContent>
                    </Card>
                </div>

                <Charges />

                {/* MODAL */}
            </div>
            <Footer />
        </div>
    )
}