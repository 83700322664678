    import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyCXUIY2lctVu-bCMDBCGzITnxXjsT60VXA",
    authDomain: "akfitnessworld01.firebaseapp.com",
    projectId: "akfitnessworld01",
    storageBucket: "akfitnessworld01.appspot.com",
    messagingSenderId: "1014967291010",
    appId: "1:1014967291010:web:5140d0c6d637bc1feab89b",
    measurementId: "G-TMXW7WLTDP"
};

firebase.initializeApp(firebaseConfig);

var db = firebase.firestore();
var storageRef = firebase.storage().ref()
var deletestorageRef = firebase.storage()
var auth = firebase.auth()
var provider = new firebase.auth.GoogleAuthProvider();

export { db, storageRef, deletestorageRef, auth, provider };


