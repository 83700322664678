import AdminNavbar from "./AdminNavbar";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { Button, Card, CardContent, LinearProgress, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import { db, storageRef } from "./Firebase";
import firebase from "firebase";
import { useState } from "react";


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export default function AdminPage() {

    const [loading, setloading] = useState(0);

    function addSsImage(e) {
        e.preventDefault()
        var data = new FormData(e.currentTarget)

        // Create the file metadata
        var file = data.get("ss_image")
        var file_name = data.get("ss_image_name")
        var metadata = {
            contentType: 'image/jpeg'
        };

        // Upload file and metadata to the object 'images/mountains.jpg'
        var uploadTask = storageRef.child('Success_story_images/' + file_name + ".png").put(file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                setloading(parseInt(progress))
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                }
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    console.log('File available at', downloadURL);

                    db.collection("Success_story_images").add({
                        Name: data.get("ss_image_name"),
                        Image: downloadURL
                    }).then((succ) => {
                        e.target.reset();
                        e.target.ss_image.focus()
                        setloading(0);
                    })

                });
            }
        );
    }

    const [loadingBt, setloadingBt] = useState(0);

    function addBtImage(e) {
        e.preventDefault()
        var data = new FormData(e.currentTarget)

        // Create the file metadata
        var file = data.get("bt_image")
        var file_name = data.get("bt_image_name")
        var metadata = {
            contentType: 'image/jpeg'
        };

        // Upload file and metadata to the object 'images/mountains.jpg'
        var uploadTask = storageRef.child('Best_transformation_images/' + file_name + ".png").put(file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                setloadingBt(parseInt(progress))
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                }
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    console.log('File available at', downloadURL);

                    db.collection("Best_transformation_images").add({
                        Name: data.get("bt_image_name"),
                        Image: downloadURL
                    }).then((succ) => {
                        e.target.reset();
                        e.target.bt_image.focus()
                        setloadingBt(0);
                    })

                });
            }
        );
    }

    const [loadingCertificate, setloadingCertificate] = useState(0);

    function addCertificateImage(e) {
        e.preventDefault()
        var data = new FormData(e.currentTarget)

        // Create the file metadata
        var file = data.get("Certificate_image")
        var file_name = data.get("Certificate_image_name")
        var metadata = {
            contentType: 'image/jpeg'
        };

        // Upload file and metadata to the object 'images/mountains.jpg'
        var uploadTask = storageRef.child('Certificate_images/' + file_name + ".png").put(file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                setloadingCertificate(parseInt(progress))
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                }
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    console.log('File available at', downloadURL);

                    db.collection("Certificate_images").add({
                        Name: data.get("Certificate_image_name"),
                        Image: downloadURL
                    }).then((succ) => {
                        e.target.reset();
                        e.target.Certificate_image.focus()
                        setloadingCertificate(0);
                    })
                });
            }
        );
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AdminNavbar />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <DrawerHeader />
                    <div className="container-fluid m-auto row">
                        <h1 className="fw-bold txt-clr text-center">Add Images</h1>
                        <div className="col-lg-6 p-2">
                            <Card>
                                <h1 className="text-center">Success Story</h1>
                                <CardContent>
                                    <form onSubmit={addSsImage}>
                                        <TextField name="ss_image_name" fullWidth label="Enter file name" margin="normal" size="small" required />
                                        <TextField fullWidth name="ss_image" type="file" margin="normal" size="small" required />
                                        {loading > 0 ? (
                                            <LinearProgress className="py-1" variant="determinate" value={loading} />
                                        ) : (
                                            <Button type="submit" className="m-3 btn-bg fw-bold" variant="contained">Upload Image</Button>
                                        )}
                                    </form>
                                </CardContent>
                            </Card>
                        </div>
                        <div className="col-lg-6 p-2">
                            <Card>
                                <h1 className="text-center">Transformation of the month</h1>
                                <CardContent>
                                    <form onSubmit={addBtImage}>
                                        <TextField name="bt_image_name" fullWidth label="Enter file name" margin="normal" size="small" required />
                                        <TextField fullWidth name="bt_image" type="file" margin="normal" size="small" required />
                                        {loadingBt > 0 ? (
                                            <LinearProgress className="py-1" variant="determinate" value={loadingBt} />
                                        ) : (
                                            <Button type="submit" className="m-3 btn-bg fw-bold" variant="contained">Upload Image</Button>
                                        )}
                                    </form>
                                </CardContent>
                            </Card>
                        </div>

                        <div className="col-lg-6 p-2">
                            <Card>
                                <h1 className="text-center">Upload Certificate</h1>
                                <CardContent>
                                    <form onSubmit={addCertificateImage}>
                                        <TextField name="Certificate_image_name" fullWidth label="Enter file name" margin="normal" size="small" required />
                                        <TextField fullWidth name="Certificate_image" type="file" margin="normal" size="small" required />
                                        {loadingCertificate > 0 ? (
                                            <LinearProgress className="py-1" variant="determinate" value={loadingCertificate} />
                                        ) : (
                                            <Button type="submit" className="m-3 btn-bg fw-bold" variant="contained">Upload Image</Button>
                                        )}
                                    </form>
                                </CardContent>
                            </Card>
                        </div>

                    </div>
                </Box>
            </Box>
        </>
    )
}