import React, { useEffect, useState } from 'react';

export default function Preloader() {
    const [complete, setComplete] = useState(false);
    const [percent, setPercent] = useState(0);
    const startTime = 600;
    let timeout = null;

    useEffect(() => {
        init();
        return () => clearTimeout(timeout);
    }, []);

    const init = () => {
        toggleComplete();
        progressDisplay();
        timeout = setTimeout(loop, startTime);
    };

    const loop = () => {
        if (!complete) {
            progressInc();
            timeout = setTimeout(loop, 17);
        }
    };

    const progressDisplay = () => {
        // Update styles or state as needed
        const percentText = `${Math.round(percent * 100)}%`;
        // Update UI with percentText
    };

    const progressInc = (amount = 0.01) => {
        if (percent < 1) {
            setPercent((prevPercent) => +Math.min(prevPercent + amount, 1).toFixed(2));
        }
        if (percent >= 1) {
            setPercent(1);
            setComplete(true);
            toggleComplete();
        }
        progressDisplay();
    };

    const replay = () => {
        if (complete) {
            setComplete(false);
            setPercent(0);
            init();
        }
    };

    const toggleComplete = () => {
        // Update styles or state as needed
    };
    return (
        <>
            <svg className="pl" viewBox="0 0 270 270" width="270px" height="270px" role="img" aria-labelledby="pl-percent">
                {/* ... Rest of your SVG code */}
                <text id="pl-percent" fill="currentcolor" fontSize="48" textAnchor="middle" x="135" y="151" data-percent>
                    {Math.round(percent * 100)}%
                </text>
                <button id="replay" className="btn" type="button" title="Replay" onClick={replay}>
                    <svg className="btn__icon" viewBox="0 0 24 24" width="24px" height="24px" aria-hidden="true">
                        <path
                            d="M5 13C5 16.866 8.13401 20 12 20C15.866 20 19 16.866 19 13C19 9.13401 15.866 6 12 6H7M7 6L10 3M7 6L10 9"
                            fill="none"
                            stroke="currentcolor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </button>
            </svg>
        </>
    )
}