import React, { useEffect, useState } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import Gallery from 'react-image-gallery';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { db } from './Firebase';

export default function SuccessStory() {
    const [imageData, setimageData] = useState([]);

    useEffect(() => {
        getSsImages();
    }, []);

    function getSsImages() {
        db.collection('Success_story_images').get().then((succ) => {
            const imagesFromDB = succ.docs.map((doc) => ({
                original: doc.data().Image,
                thumbnail: doc.data().Image,
            }));
            setimageData(imagesFromDB);
        });
    }

    return (
        <div className='success-section'>
            <div className="container m-auto p-3">
                <h1 className="text-white text-uppercase text-center display-4 fw-bold">
                    <span className='txt-clr'>Success</span> Stories
                </h1>
                <Gallery items={imageData} autoPlay={true} />
                <Grid container justifyContent={"center"}>
                    <Link to="/Services" className='btn btn-bg m-3 p-3 rounded-0 text-white text-uppercase'>
                        Make your own success story
                    </Link>
                </Grid>
            </div>
        </div>
    );
}
