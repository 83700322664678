import { Button, TextField } from '@mui/material';
import React, { Component } from 'react';

class BmrCalculator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            weight: 0,
            height: 0,
            age: 0,
            gender: 'male',
            result: 0,
        };
    }

    calculateBMR = () => {
        const { weight, height, age, gender } = this.state;
        let bmr = 0;

        if (gender === 'male') {
            bmr = 88.362 + 13.397 * weight + 4.799 * height - 5.677 * age;
        } else if (gender === 'female') {
            bmr = 447.593 + 9.247 * weight + 3.098 * height - 4.330 * age;
        }

        this.setState({ result: bmr.toFixed(2) });
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    render() {
        return (
            <div id='bmr-calculator' className='p-3'>
                <div className='container m-auto row p-lg-4 text-white text-center'>
                    <h1 className="text-white text-uppercase text-center display-4 fw-bold "> <span className='txt-clr'>Calculate</span> your bmi now!!</h1>
                    <div className='col-lg-3'>
                        <label>
                            Weight (kg)
                            <TextField
                                size='small'
                                type="number"
                                name="weight" margin='dense'
                                value={this.state.weight}
                                onChange={this.handleInputChange} className='form-control rounded-0'
                            />
                        </label>
                    </div>
                    <div className='col-lg-3'>
                        <label>
                            Height (cm)
                            <TextField
                                size='small'
                                type="number"
                                name="height" margin='dense'
                                value={this.state.height}
                                onChange={this.handleInputChange} className='form-control rounded-0'
                            />
                        </label>
                    </div>
                    <div className='col-lg-3'>
                        <label>
                            Age
                            <TextField
                                size='small'
                                type="number"
                                name="age" margin='dense'
                                value={this.state.age}
                                onChange={this.handleInputChange} className='form-control rounded-0'
                            />
                        </label>
                    </div>
                    <div className='col-lg-3'>
                        <label className='p-2 m-2 m-lg-0 w-100'>
                            Gender
                            <select name="gender" margin='dense' value={this.state.gender} onChange={this.handleInputChange} className='w-100 p-2 rounded-0 bg-white'>
                                <option value="male" selected disabled>Select</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                        </label>
                    </div>
                    <div className='col-lg-4 offset-lg-4 p-lg-3 d-flex align-items-center justify-content-center'>
                        <Button className='form-control btn-clr fw-bold' variant='contained' onClick={this.calculateBMR}>Calculate BMI</Button>
                    </div>
                    <div>
                        <p className='fs-5'>Your BMI is: {this.state.result} calories per day.</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default BmrCalculator;
