
import { Button, Card, CardContent, TextField } from "@mui/material"
import { db } from "./Firebase"
import { useEffect, useState } from "react";
import AdminPage from './AdminPage';
import { useNavigate } from "react-router-dom";

export default function AdminLogin() {

    var navi = useNavigate();

    function AdminLog(e) {
        e.preventDefault();
        var data = new FormData(e.currentTarget);
        db.collection("admin").where("username", "==", data.get("username")).where("password", "==", data.get("password")).get().then((succ) => {
            if (succ.size > 0) {
                // Login successful, store user ID in local storage
                localStorage.setItem("userId", succ.docs[0].id);
                console.log("Login successful");
                checkUser()
            } else {
                alert("Wrong Id or Password");
            }
        });
    }

    const [userid, setUserid] = useState("")

    function checkUser() {
        const user = localStorage.getItem("userId");

        if (user) {
            // User is authenticated
            setUserid(user);
            console.log(user);
        } else {
            // User is not authenticated
            console.log(null);
        }
    }


    useEffect(() => {
        checkUser();
    }, [])

    return (
        <>
            {userid ? (
                navi("/Memberships")
            ) : (
                <div className='container m-auto'>
                    <CardContent />
                    <CardContent />
                    <CardContent />
                    <CardContent />
                    <CardContent />
                    <div className="col-lg-6 offset-lg-3 p-5">
                        <Card>
                            <CardContent>
                                <h1 className="text-center">Admin Login</h1>
                                <form onSubmit={AdminLog}>
                                    <TextField margin="normal" name="username" label="enter id" size="small" fullWidth required />
                                    <TextField type="password" margin="normal" name="password" label="enter password" size="small" fullWidth required />
                                    <div className="d-flex justify-content-center">
                                        <Button variant="contained" type="submit" className="btn-bg fw-bold m-2">Submit</Button>
                                    </div>
                                </form>
                            </CardContent>
                        </Card>
                    </div>
                </div >
            )
            }

        </>
    )
}