import React from "react";
import AdminNavbar from "./AdminNavbar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, Card, CardContent } from "@mui/material";
import { Delete, QrCode2 } from "@mui/icons-material";
import { db, deletestorageRef } from "./Firebase";
import ReactQRCode from "qrcode.react";

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export default function QrCodeGenerator() {
    const [qrCodeSrc, setQRCodeSrc] = useState("");
    const [certificateData, setCertificateData] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);

    // function getCertificateImages() {
    //     db.collection("Certificate_images")
    //         .get()
    //         .then((succ) => {
    //             // setCertificateData(succ.docs.map((doc) => doc.data()));
    //             setCertificateData(succ.docs);
    //         });
    // }
    function getCertificateImages() {
        db.collection("Certificate_images")
            .get()
            .then((succ) => {
                const certificateDataWithDownloadUrls = succ.docs.map(async (doc) => {
                    const data = doc.data();
                    const imageRef = deletestorageRef.refFromURL(data.Image);
                    const downloadUrl = await imageRef.getDownloadURL();
                    return { ...data, downloadUrl };
                });

                Promise.all(certificateDataWithDownloadUrls).then((docs) => {
                    setCertificateData(docs);
                });
            });
    }

    useEffect(() => {
        getCertificateImages();
    }, []);

    // const generateQR = (imageUrl) => {
    //     // Set the QR code URL
    //     setQRCodeSrc(
    //         `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${imageUrl}`
    //     );
    //     // Set the selected image URL
    //     setSelectedImage(imageUrl);
    // };

    const generateQR = (downloadUrl) => {
        setQRCodeSrc(downloadUrl);
        setSelectedImage(downloadUrl);
    };

    function deleteCertificate(x) {
        console.log(x);
        db.collection("Certificate_images").doc(x.id).delete().then((succ) => {
            if (x.data().Image) {
                deletestorageRef.refFromURL(x.data().Image).delete().then((succ) => {
                    console.log("ok")
                    getCertificateImages();
                })
            }
        })
    }

    const downloadQRCode = (url, filename) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.rel = "noopener noreferrer";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };



    return (
        <>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AdminNavbar />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <DrawerHeader />
                    <div className="container m-auto row">
                        <h1 className="text-center txt-clr fw-bold">Generate QR Code</h1>
                        <div className="col-lg-6">
                            <Card>
                                <CardContent>
                                    <h2 className="text-center">QR Code</h2>
                                    {selectedImage && (
                                        <div className="text-center mt-4">
                                            <ReactQRCode value={selectedImage} size={150} />
                                            <br />
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => downloadQRCode(qrCodeSrc, "qrcode.png")}
                                            >
                                                Download QR Code
                                            </button>
                                        </div>
                                    )}
                                </CardContent>
                            </Card>
                        </div>
                        <div className="py-2 py-lg-0 col-lg-6">
                            <Card>
                                <CardContent>
                                    <h1 className="text-center">Certificates</h1>
                                    <table className="table table-hover">
                                        <thead>
                                            <tr className="text-center">
                                                <th>Name</th>
                                                <th>Image</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {certificateData.map((row) => (
                                                <tr key={row.id}>
                                                    <td className="text-center">{row.Name}</td>
                                                    <td className="d-flex justify-content-center">
                                                        <img
                                                            src={row.Image}
                                                            width={50}
                                                            alt={`Certificate ${row.Name}`}
                                                        />
                                                    </td>
                                                    <td className="text-center">
                                                        <ButtonGroup>
                                                            <Button onClick={() => deleteCertificate(row)} color="error">
                                                                <Delete />
                                                            </Button>
                                                            <Button onClick={() => generateQR(row.downloadUrl)} color="secondary">
                                                                <QrCode2 />
                                                            </Button>
                                                        </ButtonGroup>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </Box>
            </Box>
        </>
    );
}
