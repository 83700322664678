import { Link } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { Grid } from "@mui/material";
import phoneImg from "./images/Contact Us Vector Illustration Part 02 copy.png"
import chatImg from "./images/Group Chat Illustration copy.png"
export default function Contact() {
    return (
        <div id="contact" className="overflow-scrolls">
            <Navbar />
            <div className="container-fluid row m-auto">
                <h1 className="text-white text-uppercase text-center display-4 fw-bold"><span className='txt-clr'>Contact</span> Us</h1>
                <div className="col-lg-6 d-flex flex-column justify-content-center p-5">
                    <div className="d-flex justify-content-center">
                        <img src={chatImg} className="w-75" />
                    </div>
                    <Grid container justifyContent={"center"}>
                        <a href="https://wa.me/+919814376228" className="btn btn-bg text-white p-3">Launch the chat</a>
                    </Grid>
                </div>
                <div className="col-lg-6 d-flex flex-column justify-content-center p-5">
                    <div className="d-flex justify-content-center">
                        <img src={phoneImg} className="w-75" />
                    </div>
                    <Grid container justifyContent={"center"}>
                        <a href="tel:+919814376228" className="btn btn-bg text-white p-3">Make the call</a>
                    </Grid>
                </div>
            </div>
            <Footer />
        </div>
    )
}