import AdminNavbar from "./AdminNavbar";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { db } from "./Firebase";
import React from "react";
import { Button, Card, CardContent } from "@mui/material";
import { Delete } from "@mui/icons-material";



const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export default function Subscribers() {

    const [Newsletter, setNewsletter] = useState([]);
    function getNewsletter() {
        db.collection("Users_info").get().then((succ) => {
            setNewsletter(succ.docs)
        })
    }

    useEffect(() => {
        getNewsletter();
    }, [])

    function deleteMembers(x) {
        console.log(x);
        db.collection("Users_info").doc(x.id).delete().then((succ) => {
            getNewsletter();
        })
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AdminNavbar />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <DrawerHeader />
                    <div className="container-fluid m-auto row">
                        <h1 className="text-center fw-bold txt-clr">News Letter Data</h1>
                        <Card>
                            <CardContent>
                                <table class="table table-hover">
                                    <thead>
                                        <tr className="text-center">
                                            <th>Name</th>
                                            <th>Email ID</th>
                                            <th>Mobile Number</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Newsletter.map((row) => (
                                            <tr className="text-center">
                                                <td>{row.data().Name}</td>
                                                <td>{row.data().Email_ID}</td>
                                                <td>{row.data().Mobile_number}</td>
                                                <td>
                                                    <Button onClick={() => deleteMembers(row)}><Delete /></Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </CardContent>
                        </Card>
                    </div>
                </Box>
            </Box>
        </>
    )
}