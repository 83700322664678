import React from 'react';

import htLogo from './images/hindustantimes-logo.png';
import tribuneLogo from './images/thetribune-logo.jpg';
import oneIndiaLogo from './images/oneindia-logo.png';
import jagbaniLogo from './images/jagbani-logo.png';
import jujhartLogo from './images/jujhartimes-logo.png';
import deshvideshLogo from './images/deshvideshtimes-logo.png';

export default function NewspaperSlider() {
    return (
        <div id='newspaper-slider'>
            <div className='container-fluid row m-auto p-2'>
                <img src={jujhartLogo} className='col-lg-2 col-4' />
                <img src={jagbaniLogo} className='col-lg-2 col-4' />
                <img src={tribuneLogo} className='col-lg-2 col-4' />
                <img src={htLogo} className='col-lg-2 col-4' />
                <img src={oneIndiaLogo} className='col-lg-2 col-4' />
                <img src={deshvideshLogo} className='col-lg-2 col-4' />
            </div>
        </div>
    )
}
