import { Button, TextField, Typography } from '@mui/material';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { db } from './Firebase';
import { ArrowRight } from '@mui/icons-material';
import gpayQr from './images/gpay-qr-code.jpg'



export default function Charges() {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    function addUserDetails(e) {
        e.preventDefault()
        var data = new FormData(e.currentTarget);
        db.collection("Users").add({
            Name: data.get("userName"),
            Email_ID: data.get("userEmail"),
        }).then((succ) => {
            e.target.reset();
            e.target.userName.focus();
        })
    }

    return (
        <>
            <section id="home-page-charges">
                <div className="container m-auto">
                    <h1 className="text-white text-uppercase text-center display-4 fw-bold"><span className='txt-clr'>Select</span> a suitable plan</h1>
                    <Typography className="text-white text-end">Swip to see more plans<ArrowRight /></Typography>
                    <Carousel responsive={responsive}>
                        <div className="p-2">
                            <div className="card text-center text-white p-3 card-h">
                                <div className="card-body p-3">
                                    <h1 className="card-title txt-clr p-3">MONTHLY PLAN</h1>
                                    <h3>Features</h3>
                                    <ul className="list-group list-group-flush text-start fw-light">
                                        <li className="list-group-item bg-transparent text-white">- Personalized workout plan for the month.</li>
                                        <li className="list-group-item bg-transparent text-white">- Nutrition guidance for the specified period.</li>
                                        <li className="list-group-item bg-transparent text-white">- Regular progress tracking and check-ins.</li>
                                        <li className="list-group-item bg-transparent text-white">- Limited access to fitness workshops or classes.</li>
                                    </ul>
                                    <h1>1 month<span className="fs-5 fw-light p-3">/&nbsp;₹&nbsp;2,499</span></h1>
                                    <a data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn btn-bg text-white">LET'S DO IT</a>
                                    <p className="card-text p-3">*subscription billed every 1 months*</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-2">
                            <div className="card text-center text-white p-3 card-h">
                                <div className="card-body p-3">
                                    <h1 className="card-title txt-clr p-3">3 MONTHS PLAN</h1>
                                    <h3>Features</h3>
                                    <ul className="list-group list-group-flush text-start fw-light">
                                        <li className="list-group-item bg-transparent text-white">- Progressive workout plans for continuous improvement.</li>
                                        <li className="list-group-item bg-transparent text-white">- Comprehensive nutrition guidance for the entire period.</li>
                                        <li className="list-group-item bg-transparent text-white">- Weekly or bi-weekly check-ins for feedback and adjustments.</li>
                                        <li className="list-group-item bg-transparent text-white">- Inclusion of a personalized fitness workshop.</li>
                                    </ul>
                                    <h1>3 months<span className="fs-5 fw-light p-3">/&nbsp;₹&nbsp;5,999</span></h1>
                                    <Typography className="fw-bold p-2">(Save ₹1,498 compared to monthly)</Typography>
                                    <a data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn btn-bg text-white">LET'S DO IT</a>
                                    <p className="card-text p-1 fw-light">*subscription billed every 3 months*</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-2">
                            <div className="card text-center text-white p-3 card-h">
                                <div className="card-body p-3">
                                    <h1 className="card-title txt-clr p-3">6 MONTHS PLAN</h1>
                                    <h3>Features</h3>
                                    <ul className="list-group list-group-flush text-start fw-light">
                                        <li className="list-group-item bg-transparent text-white">- Extended workout plans to support long-term goals.</li>
                                        <li className="list-group-item bg-transparent text-white">- Continuous nutritional support and guidance.</li>
                                        <li className="list-group-item bg-transparent text-white">- Regular check-ins for sustained motivation and progress tracking.</li>
                                        <li className="list-group-item bg-transparent text-white">- Access to exclusive fitness resources.</li>
                                    </ul>
                                    <h1>6 months<span className="fs-5 fw-light p-3">/&nbsp;₹&nbsp;9,999</span></h1>
                                    <Typography className="fw-bold p-2"> (Save ₹4,995 compared to monthly)</Typography>
                                    <a data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn btn-bg text-white">LET'S DO IT</a>
                                    <p className="card-text p-1 fw-light">*subscription billed every 6 months*</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-2">
                            <div className="card text-center text-white p-3 card-h">
                                <div className="card-body p-3">
                                    <h1 className="card-title txt-clr p-3">ANNUAL PLAN</h1>
                                    <h3>Features</h3>
                                    <ul className="list-group list-group-flush text-start fw-light">
                                        <li className="list-group-item bg-transparent text-white">- Year-long workout program for comprehensive fitness.</li>
                                        <li className="list-group-item bg-transparent text-white">- Ongoing nutritional coaching and meal planning.</li>
                                        <li className="list-group-item bg-transparent text-white">- Monthly or quarterly assessments for goal tracking.</li>
                                        <li className="list-group-item bg-transparent text-white">- Priority access to special events and workshops.</li>
                                    </ul>
                                    <h1>12 months<span className="fs-5 fw-light p-3">/&nbsp;₹&nbsp;14,999</span></h1>
                                    <Typography className="fw-bold p-2"> (Save ₹9,981 compared to monthly)</Typography>
                                    <a data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn btn-bg text-white">LET'S DO IT</a>
                                    <p className="card-text p-1 fw-light">*subscription billed every 12 months*</p>
                                </div>
                            </div>
                        </div>
                    </Carousel>


                    {/* MODAL */}

                    {/* <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-3 txt-clr text-uppercase " id="exampleModalLabel">Fill Details</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <form onSubmit={addUserDetails}>
                                    <div className="px-2">
                                        <TextField size="small" margin="normal" fullWidth name="userName" type="text" label='Enter Your Name' />
                                    </div>
                                    <div className="px-2">
                                        <TextField size="small" margin="normal" fullWidth name="userEmail" type="email" label='Enter Your Email ID' />
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <Button type="submit" variant="contained" className="btn-bg fw-bold m-2">Submit</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> */}

                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-3 txt-clr text-uppercase" id="exampleModalLabel">Pay Here !</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <Typography className='text-center'>
                                    We are currently working on additional payment methods. In the meantime, you can make payments using the QR code, and instructions are provided below.
                                </Typography>

                                <div className='d-flex justify-content-center'>
                                    <img src={gpayQr} className='w-50' />
                                </div>
                                <div className='text-center'>
                                    <p>How to pay?</p>
                                    <ul className='px-2 text-start' style={{ listStyle: 'none' }}>
                                        <li className='my-1'>
                                            1. If you are a mobile user:
                                            <ul style={{ listStyle: 'disc' }}>
                                                <li className='fw-light'>- Simply tap and hold the QR code.</li>
                                            </ul>
                                        </li>

                                        <li className='my-1'>
                                            2. For iPhone users:
                                            <ul style={{ listStyle: 'disc' }}>
                                                <li className='fw-light'>- Options will pop up.</li>
                                                <li className='fw-light'>- Select the Google Pay option, and it will lead you to our Google Pay account.</li>
                                            </ul>
                                        </li>

                                        <li className='my-1'>
                                            3. If you are a PC or Laptop user:
                                            <ul style={{ listStyle: 'disc' }}>
                                                <li className='fw-light'>- Right-click on the QR code and save the image.</li>
                                                <li className='fw-light'>- Open Google Pay.</li>
                                                <li className='fw-light'>- Choose the "Scan any QR code" option.</li>
                                                <li className='fw-light'>- Select "Upload from gallery" to lead you to our Google Pay account.</li>
                                            </ul>
                                        </li>
                                    </ul>


                                </div>
                            </div>
                        </div>
                    </div>

                    {/* MODAL */}
                </div>
            </section>
        </>
    )
}