import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import Footer from "./Footer";
import Navbar from "./Navbar";
import NewspaperSlider from "./NewspaperSlider";
import blogOne from "./images/blog-img-1.webp"
import blogTwo from "./images/blog-img-3.webp"
import blogThree from "./images/blog-img-5.webp"
import blogFour from "./images/blog-img-7.webp"
import blogFive from "./images/blog-img-8.webp"
import blogSix from "./images/blog-img-9.webp"
import blogSeven from "./images/blog-img-10.webp"
import { More } from "@mui/icons-material";
export default function Blog() {
    return (
        <div className="overflow-scrolls">
            <Navbar />
            <div className="container-fluid row m-auto">
                <h1 className="txt-clr text-uppercase text-center display-4 fw-bold">Blog</h1>
                <Card className="row m-2 bg-transparent text-white">
                    <CardContent className="col-lg-6">
                        <Grid container justifyContent={"start"}>
                            <img src={blogOne} className="w-100" />
                        </Grid>
                    </CardContent>
                    <CardContent className="col-lg-6">
                        <h3 className="text-center display-6 text-uppercase">Coach of the year 2022</h3>
                        <Typography>At the event, which was held in Jalandhar, Punjab and was organized by Radio City 91.9 FM, renowned fitness coach Ankush Kumar was presented with the Punjab Icon Award as the Best Fitness Coach. The event was organized by FM Radio, and the Punjab cabinet minister presented the Awards to Ankush Kumar and the other Awardees in appreciation of their exceptional achievements and contributions in their respective fields.</Typography>
                        <a href="https://www.hindustanmetro.com/fitness-coach-ankush-kumar-wins-punjab-icon-award-by-radio-city-91-9-fm-jalandhar/"><Button className="txt-clr fw-bold">direct to article<More className="txt-clr m-4" /></Button></a>
                    </CardContent>
                </Card>

                <Card className="col-lg-6 bg-transparent text-white">
                    <CardContent>
                        <h3 className="text-center display-6 text-uppercase">ironman of india</h3>
                    </CardContent>
                    <CardContent>
                        <img src={blogTwo} className="w-100" />
                    </CardContent>
                </Card>
                <Card className="col-lg-6 bg-transparent text-white">
                    <CardContent>
                        <h3 className="text-center display-6 text-uppercase">Best transformation coach 2023</h3>
                    </CardContent>
                    <CardContent>
                        <img src={blogThree} className="w-100" />
                    </CardContent>
                </Card>
                <Card className="col-lg-6 bg-transparent text-white">
                    <CardContent>
                        <h3 className="text-center display-6 text-uppercase">Pride of punjab from ggi khanna</h3>
                    </CardContent>
                    <CardContent>
                        <img src={blogFour} className="w-100" />
                    </CardContent>
                </Card>
                <Card className="col-lg-6 bg-transparent text-white">
                    <CardContent>
                        <h3 className="text-center display-6 text-uppercase">best judging award </h3>
                    </CardContent>
                    <CardContent>
                        <img src={blogFive} className="w-100" />
                    </CardContent>
                </Card>
                <Card className="col-lg-6 bg-transparent text-white">
                    <CardContent>
                        <h3 className="text-center display-6 text-uppercase">Kids athletic meet - probono</h3>
                    </CardContent>
                    <CardContent>
                        <img src={blogSix} className="w-100" />
                    </CardContent>
                </Card>
                <Card className="col-lg-6 bg-transparent text-white">
                    <CardContent>
                        <h3 className="text-center display-6 text-uppercase">Doctorate award</h3>
                    </CardContent>
                    <CardContent>
                        <img src={blogSeven} className="w-100" />
                    </CardContent>
                </Card>
                <NewspaperSlider />
                <Footer />
            </div >
        </div >
    )
}