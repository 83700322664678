import React, { useEffect, useState } from "react";
import AdminNavbar from "./AdminNavbar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { db } from "./Firebase";
import { Button, Card, CardContent } from "@mui/material";
import { CropDin, Delete } from "@mui/icons-material";

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export default function Memberships() {
    const [members, setmembers] = useState([]);

    function getmembers() {
        db.collection("Users").get().then((succ) => {
            setmembers(succ.docs);
        })
    }

    useEffect(() => {
        getmembers();
    }, [])

    function deleteMembers(x) {
        console.log(x);
        db.collection("Users").doc(x.id).delete().then((succ) => {
            getmembers();
        })
    }

    return (
        <>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AdminNavbar />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <DrawerHeader />
                    <div className="container m-auto row">
                        <h1 className="text-center txt-clr fw-bold">Client With Memberships</h1>
                        <Card>
                            <CardContent>
                                <table className="table table-hover">
                                    <thead>
                                        <tr className="text-center">
                                            <th>Name</th>
                                            <th>Email-ID</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {members.map((row) => (
                                            <tr className="text-center">
                                                <td>{row.data().Name}</td>
                                                <td>{row.data().Email_ID}</td>
                                                <td>
                                                    <Button color="error" onClick={() => deleteMembers(row)}><Delete /></Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </CardContent>
                        </Card>
                    </div>
                </Box>
            </Box>
        </>
    );
}
