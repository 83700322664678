import { Button, Card, CardContent, TextField, Typography } from "@mui/material";
import Navbar from "./Navbar";
import akFig from "./images/Ak-fig-4.png"
import nutritionIcon from "./images/nutrition-icon.png"
import workoutIcon from "./images/workout-icon.png"
import supportIcon from "./images/support-icon.png"
import adviceIcon from "./images/advice-icon.png"
import BmrCalculator from "./BmrCalculator"
import SuccessStory from "./SuccessStroy";
import Footer from "./Footer";
import TransOfMonth from "./TransOfMonth";
import { ArrowForward, ArrowRight, ArrowRightAlt, Forward } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { db } from "./Firebase";
import Charges from './Charges';

export default function Home() {

    function addUserDetailsNewsletter(e) {
        e.preventDefault()
        var data = new FormData(e.currentTarget);
        db.collection("Users_info").add({
            Name: data.get("user_name"),
            Email_ID: data.get("user_email"),
            Mobile_number: data.get("user_mobile"),
        }).then((succ) => {
            e.target.reset();
            e.target.user_name.focus();
        })
    }

    return (
        <div className="overflow-scrolls">
            <section id="hero-section">
                <Navbar />
                <div className="container-fluid m-auto px-lg-5 px-3 row">
                    <div className="col-lg-6 d-none d-lg-block hero-img-bg">
                        <img src={akFig} className="w-75" />
                    </div>
                    <div className="col-lg-6 text-white pt-2 text-center pt-lg-5">
                        <h1 className="display-1 text-capitalize">Train With certified trainer Ankush Kumar</h1>
                        <a href="https://wa.me/+919814376228"><Button className="btn-bg fw-bold text-capitalize fs-6 m-2 rounded-0" variant="contained">Book Appointment</Button></a>
                    </div>
                </div>
            </section>

            <section className="bg-dark p-3">
                <div className="container m-auto">
                    <div className="d-lg-flex justify-content-evenly">
                        <div className="text-center">
                            <p className="text-white text-capitalize"><span className="txt-clr fs-1 fw-bolder">20+</span>
                                <br />
                                years in fitness industry.</p>
                        </div>

                        <div className="text-center">
                            <p className="text-white text-capitalize"><span className="txt-clr fs-1 fw-bolder">3000+</span>
                                <br />
                                clients trained.</p>
                        </div>

                        <div className="text-center">
                            <p className="text-white text-capitalize fs-1 fw-bolder"><span className="txt-clr ">BODYBUILDING </span> JUDGE
                            </p>
                        </div>

                    </div>
                </div>
            </section>

            <TransOfMonth />

            <section className="bg-dark p-3">
                <div className="container m-auto">
                    <h1 className="text-white text-capitalize display-3 fw-bold">Get <span className="txt-clr ">International</span> personal trainer certificate</h1>
                    <a href="https://wa.me/+919814376228" className="text-decoration-none">
                        <Button variant="contained" className="btn-bg fs-5 fw-bolder">Get Started&nbsp;
                            <ArrowForward />
                        </Button>
                    </a>
                </div>
            </section>

            <SuccessStory />

            <section id="section-2" >
                <div className="container m-auto row p-4">
                    <h1 className="text-uppercase text-center display-4 fw-bold"><span className="txt-clr">Why ?</span> People Need Health Coach</h1>
                    <div className="col-lg-3 col-md-6 text-center">
                        <Card className="my-bg shadow">
                            <CardContent>
                                <img src={nutritionIcon} className="card-img-w w-50" />
                                <h5 className="p-2">Nutrition Strategies</h5>
                                <Typography>Healthy diet for body as well as for mind.</Typography>
                            </CardContent>
                        </Card>
                    </div>
                    <div className="col-lg-3 col-md-6 text-center">
                        <Card className="my-bg shadow">
                            <CardContent>
                                <img src={workoutIcon} className="card-img-w w-50" />
                                <h5 className="p-2">Workout Routines</h5>
                                <Typography>Taking care of your body is important.</Typography>
                            </CardContent>
                        </Card>
                    </div>
                    <div className="col-lg-3 col-md-6 text-center">
                        <Card className="my-bg shadow">
                            <CardContent>
                                <img src={supportIcon} className="card-img-w w-50" />
                                <h5 className="p-2">Individual Support</h5>
                                <Typography>More of a friend than a health coach.</Typography>
                            </CardContent>
                        </Card>
                    </div>
                    <div className="col-lg-3 col-md-6 text-center">
                        <Card className="my-bg shadow">
                            <CardContent>
                                <img src={adviceIcon} className="card-img-w w-50" />
                                <h5 className="p-2">First-Hand Advice</h5>
                                <Typography>Broad experience is always a bonus.</Typography>
                            </CardContent>
                        </Card>
                    </div>

                    <h6 className="p-4 fw-light text-center fs-5">I am delighted to be able to share with you the techniques I have learnt over the years
                        that have brought me much happiness and success.</h6>
                </div>
            </section>

            <Charges />

            <BmrCalculator />

            <section className="bg-dark p-3">
                <div className="container m-auto">

                    <h1 className="display-5 text-white text-center text-uppercase fw-bold p-2"><span className="txt-clr">subscribe</span> to our newsletter</h1>
                    <Card className="col-lg-6 offset-lg-3">
                        <CardContent>
                            <form onSubmit={addUserDetailsNewsletter}>
                                <TextField name="user_name" fullWidth required size="small" margin="normal" label="Enter your name" />
                                <TextField name="user_email" type="email" fullWidth required size="small" margin="normal" label="Enter your email id" />
                                <TextField name="user_mobile" type="number" fullWidth required size="small" margin="normal" label="Enter your phone number" />
                                <div className="d-flex justify-content-center">
                                    <Button type="submit" variant="contained" className="btn-bg fw-bold">Submit</Button>
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                </div>
            </section>

            <Footer />

        </div>
    )
}