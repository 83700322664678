import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import brandLogo from './images/2.png'

export default function Footer() {
    return (
        <>
            <footer className="text-center footer-bg p-4">
                <div className="container m-auto">
                    <Link to='/AdminLogin' target="_blank"><img src={brandLogo} className="w-25 rounded-circle footer-logo" /></Link>
                    <br />
                    <Typography className="text-white fw-light">Website Designer : Abhinav Gupta</Typography>
                    <Typography className="text-white fw-light">Contact : 6239605078</Typography>
                </div>
            </footer>
        </>
    )
}